.portfolio {
    background-color: #000000;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-bottom: 50px;
}

.portfolio h1 {
    font-size: 55px;
    color: #ffffff;
    padding-top: 50px;
    margin-bottom: 50px;
}

.swiper {
    width: 100%;
    height: 100%;
    padding-bottom: 100px;
}

.swiper-pagination {
    bottom: 100px;
}

.swiper-pagination-bullet {
    width: 15px;
    height: 15px;
    background-color: #D9D9D9;
    opacity: 1;
}

.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 3px);
}

.swiper-pagination-bullet-active {
    transform: scale(1.2);
    background-color: #ffffff;
}

.swiper-slide {
    height: 580px;
    text-align: center;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    transform: scale(0.9);
}

.swiper-slide-active {
    transform: scale(1);
    transform: rotateY(360deg);
    transition: all linear 0.3s;
}

.slide {
    width: 100%;
    height: 100%;
    padding: 10%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    border-radius: 30px;
}

.slide .front-face {
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: #5025D1;
    position: absolute;
    border-radius: 30px;
    visibility: visible;
}

.swiper-slide-active .front-face {
    right: 100%;
    transition: all linear 0.3s;
    visibility: hidden;
}

.slide .button {
    padding: 10px;
    display: flex;
    background: #5025D1;
    border: 2px solid #5025D1;
    box-shadow: 3px 3px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    font-weight: 500;
    font-size: 20px;
    text-align: center;
    color: #FFFFFF;
    justify-content: center;
    text-decoration: none;
}
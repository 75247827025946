.header {
    background-color: #000000 !important;
    min-height: 109px;
}

.header .brand {
    font-size: 26px;
}

.header nav a {
    font-size: 20px;
    color: #ffffff;
    margin-right: 20px;
}

.header nav a:last-child {
    margin-right: 0;
}

.objective {
    min-height: 90vh;
    background: url(../../images/objective-bg.png);
    background-size: cover;
    background-position: right;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}

.objective h1,
.objective h2 {
    color: #ffffff;
    font-weight: 400;
}

.objective h1 {
    font-size: 3rem;
}

@media screen and (min-width: 480px) {
    .objective {
        background-position: center;
    }

    .objective h1 {
        font-size: 100px;
    }
}

.objective h2 {
    font-size: 30px;
}

.objective h2 .ornament {
    font-size: 25px;
}

.objective .rolldown {
    height: 200px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-bottom: 100px;
    position: relative;
}

.objective .rolldown button {
    border: 0;
    background-color: transparent;
    position: absolute;
    animation-name: rolldownAnim;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    -o-animation-direction: alternate-reverse;
}

@keyframes rolldownAnim {
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(20px);
    }

    100% {
        transform: translateY(0);
    }
}
.about {
    min-height: 577px;
    background-color: #5025D1;
    background-image: url(../../images/about-bg.png);
    background-size: contain;
    background-repeat: no-repeat;
    color: #ffffff;
    display: flex;
    justify-content: flex-end;
}

.about h1 {
    font-size: 55px;
    margin-bottom: 20px;
}

.about .content {
    padding-top: 100px;
    max-width: 593px;
    text-align: right;
    margin-left: auto;
}

.about .content p {
    font-size: 20px;
    font-weight: 300;
}
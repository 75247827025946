.footer {
    min-height: 400px;
    background-color: #000000;
    color: #ffffff;
    padding: 50px 0;
}

.footer {
    display: flex;
    align-items: center;
}

.footer h1 {
    font-size: 45px;
    margin-bottom: 20px;
}

.footer a {
    display: block;
    margin-bottom: 12px;
    text-decoration: none;
    color: #ffffff;
    font-size: 24px;
}

.footer img {
    width: 48px;
    margin-right: 10px;
}